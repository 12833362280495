import { createSlice } from '@reduxjs/toolkit'

const initialeState = {
    currentCart: {},
    shippingOptions: [],
    tips: [],
};

const slice = createSlice({
    name: 'cart',
    initialState: initialeState,
    reducers: {
        setCurrentCart: (
            state,
            { payload }
        ) => {
            state.currentCart = payload
        },
        setShippingOptions: (
            state,
            { payload }
        ) => {
            state.shippingOptions = payload
        },
        setTips: (
            state,
            { payload }
        ) => {
            state.tips = payload
        },
    },
})

export const { setCurrentCart, setShippingOptions, setTips } = slice.actions

export default slice.reducer
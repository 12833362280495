import { PayPalButtons } from "@paypal/react-paypal-js";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAddPaymentSessionsMutation, useSetPaymentSessionMutation } from "../../services/cart";
import { setCurrentCart } from "../../slices/cart";
import { setToast } from "../../slices/global";


const Component = ({ parent_cart_id }) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const { currentCart } = useSelector(state => state.cart);
    const amount = currentCart.total;
    const currency = currentCart.region.currency_code;
    const [setPayment, { isLoading }] = useSetPaymentSessionMutation();
    const [addPayments, { isLoading: isLoading2 }] = useAddPaymentSessionsMutation();

    return (
        <PayPalButtons
            disabled={!currentCart.email}
            forceReRender={[amount, currency]}
            fundingSource={undefined}
            createOrder={() => {
                return new Promise(async (resolve, reject) => {
                    if (currentCart.payment_session?.data?.id) {
                        resolve(currentCart.payment_session.data.id);
                    } else {
                        try {
                            await addPayments(currentCart.id).unwrap()
                            const res = await setPayment({ id: currentCart.id, params: { provider_id: 'paypal' } }).unwrap()
                            if (res.cart) {
                                dispatch(setCurrentCart(res.cart));
                                resolve(res.cart.payment_session.data.id)
                            }
                        } catch (error) {

                            dispatch(setToast({
                                visible: true,
                                type: "danger",
                                title: "Error",
                                text: error.data?.message
                            }))
                            reject();
                        }
                    }
                });
            }}
            onApprove={function (data, actions) {
                return actions.order.authorize().then(function (transaction) {
                    if (transaction.status !== "COMPLETED") {
                        return
                    }
                    history.replace(parent_cart_id ?
                        `/complete?id=${currentCart.id.split('cart_')[1]}&parent_cart_id=${parent_cart_id}&sold_variant=${currentCart.items[0]?.variant_id}`
                        :
                        `/complete?id=${currentCart.id.split('cart_')[1]}`
                    )
                });
            }}
            className="paypal-btn"
            style={{
                label: "pay",
                height: 55,
                width: "100%"
            }}
        />
    )
}

export default Component


import React, { } from "react";
import { Footer, Header } from "../../components";
import { BsEmojiNeutral } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Page = () => {

    const { t } = useTranslation();
    
    return (
        <>
            <div className="flex-center" >
                <BsEmojiNeutral size={100} color="#e5e5e5" />
                <h5 className="mt-4" >{t('checkout.Product Not Valid')}</h5>
            </div>
        </>
    );
}

export default Page;
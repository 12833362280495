import { useTranslation } from "react-i18next"

const Component = ({ className }) => {
    const { t } = useTranslation();

    return (
    <header className="wcf-pre-checkout-offer-wrapper">
        <div className="container">
        <div className="row">
           <div className={`wcf-pre-checkout-offer-wrapper-cont ${className}`}>
                <div className="wcf-progress-bar-nav">
                   <div className="wcf-pre-checkout-progress">
                      <div className="wcf-nav-bar-step active">
                         <div className="wcf-nav-bar-title">{t('Order Submitted')}</div>
                         <div className="wcf-nav-bar-step-line">
                            <div className="wcf-progress-nav-step"></div>
                         </div>
                      </div>
                      <div className="wcf-nav-bar-step active inprogress">
                         <div className="wcf-nav-bar-title">{t('Special Offer')}</div>
                         <div className="wcf-nav-bar-step-line">
                            <div className="wcf-progress-nav-step"></div>
                         </div>
                      </div>
                      <div className="wcf-nav-bar-step">
                         <div className="wcf-nav-bar-title">{t('Order Receipt')}</div>
                         <div className="wcf-nav-bar-step-line">
                            <div className="wcf-progress-nav-step"></div>
                         </div>
                      </div>
                   </div>
                </div>
            </div>
        </div>
        </div>
    </header>
    )
}

export default Component
const languages = {
    en: {
        translation: require('./en.json'),
    },
    fr: {
        translation: {
            translation: require('./fr.json'),
        },
    },
}
export default languages
import { useState } from "react";
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux";
import { Loader } from "../../components";
import { useDeleteDiscountMutation, usePostCartMutation } from "../../services/cart";
import { setCurrentCart } from "../../slices/cart";
import { setToast } from "../../slices/global";

const Component = ({ className, cart }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [show, setshow] = useState(false);
    const [coupon, setcoupon] = useState("");
    const [postCart, { isLoading }] = usePostCartMutation();
    const [deleteDiscount, { isLoading: isLoading2 }] = useDeleteDiscountMutation();
    const applied = cart.discounts?.length > 0;

    const handleAddDelete = async () => {
        try {
            let res = {};
            if (applied) {
                res = await deleteDiscount({ id: cart.id, discount: cart.discounts[0].code }).unwrap()
            } else {
                res = await postCart({ id: cart.id, params: { discounts: [{ code: coupon }] } }).unwrap()
            }
            if (res.cart) {
                dispatch(setCurrentCart(res.cart));
            }
            if (applied) {
                setcoupon('')
                setshow(false)
            }
        } catch (err) {
            dispatch(setToast({
                visible: true,
                type: "danger",
                title: "Error",
                text: err.data?.message
            }))
        }
    }

    return (
        <div className={`coupon ${className}`}>
            {!applied && !show && <a type="button" onClick={() => setshow(true)}  >{t('checkout.You have a coupon code ?')}</a>}
            {(show || applied) &&
                <div className={`d-flex align-items-center mt-3 ${applied && 'coupon-applied'}`} >
                    <input
                        className="form-control me-3"
                        placeholder={t(applied ? `checkout.Coupon Code` : 'checkout.Enter a coupon code', { coupon: applied ? cart.discounts[0].code : '' })}
                        value={applied ? '' : coupon}
                        onChange={({ target }) => setcoupon(target.value)}
                    />
                    <button
                        type="button"
                        className={`btn btn-black ${isLoading || isLoading2 ? 'loading': ''}`}
                        onClick={handleAddDelete} >
                        {isLoading || isLoading2 ? <Loader  size={20}/> : t(applied ? 'checkout.Remove' : 'checkout.Apply')}
                    </button>
                </div>
            }
        </div>
    )
}

export default Component


import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PhoneInput } from "../../components";
import { getDeliveryPayload } from "../../helpers/delivery";
import { validateEmail } from "../../helpers/validation";
import { usePostCartMutation } from "../../services/cart";
import { setCurrentCart } from "../../slices/cart";
import { setToast } from "../../slices/global";
import { getStatesByCountryCode } from "countries-states-data";
import { BsInfoCircle } from "react-icons/bs";

const Component = ({ formRef }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { currentCart } = useSelector(state => state.cart)
    const [postCart, { isLoading }] = usePostCartMutation();
    const [states, setstates] = useState([])

    useEffect(()=>{
        if(currentCart.shipping_address?.country_code){
            setstates(getStatesByCountryCode(currentCart.shipping_address?.country_code.toUpperCase()))
        }else{
            setstates(getStatesByCountryCode(currentCart.region?.countries[0]?.iso_2?.toUpperCase()))
        }
    },[currentCart.shipping_address?.country_code])

    const handleChange = async () => {
        try {
            const params = getDeliveryPayload(formRef, currentCart);
            if (!params.email) {
                return;
            }
            if (!validateEmail(params.email)) {
                return dispatch(setToast({
                    visible: true,
                    type: "danger",
                    title: "Error",
                    text: t('Please set a valid email !')
                }))
            }
            updateInfo(params)
        } catch (err) {
            dispatch(setToast({
                visible: true,
                type: "danger",
                title: "Error",
                text: err.data?.message
            }))
        }
    }

    const updateInfo = async (params) => {
        const res = await postCart({
            id: currentCart.id,
            params
        }).unwrap()

        if (res.cart) {
            dispatch(setCurrentCart(res.cart));
        }
    }

    const handleCountryChange = (e) => {
        if (e.target.value) {
            const data = getStatesByCountryCode(e.target.value.toUpperCase());
            setstates(data)
        }
    }

    return (
        <>
            <div className="form_infos mb-4">
                <h5 className="mb-4">{currentCart.items[0].variant.is_only_digital ? t('checkout.Information') : t('checkout.Shipping information')}</h5>
                <div className="mb-3">
                    <div className="float-input">
                        <input
                            onBlur={handleChange}
                            required
                            defaultValue={currentCart.email}
                            placeholder={t('checkout.Please enter your email address')}
                            type="email"
                            name="email"
                            className="form-control" />
                        <label className="form-label">{t('checkout.Email')} <span className="text-red" >*</span></label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mb-3">
                        <div className="float-input">
                            <input
                                onBlur={handleChange}
                                required
                                placeholder={t('checkout.First Name')}
                                type="text"
                                name="first_name"
                                defaultValue={currentCart.shipping_address?.first_name}
                                className="form-control" />
                            <label className="form-label">{t('checkout.First Name')} <span className="text-red" >*</span></label>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div className="float-input">
                            <input
                                onBlur={handleChange}
                                required
                                placeholder={t('checkout.Last Name')}
                                type="text"
                                name="last_name"
                                defaultValue={currentCart.shipping_address?.last_name}
                                className="form-control" />
                            <label className="form-label">{t('checkout.Last Name')} <span className="text-red" >*</span></label>
                        </div>
                    </div>
                </div>
                {!currentCart.items[0].variant.is_only_digital &&
                    <>
                        <div className="row ">
                            <div className="col-12 mb-3">
                                <div className="float-input">
                                    <input
                                        onBlur={handleChange}
                                        required
                                        placeholder={t('checkout.Address')}
                                        type="text"
                                        name="address_1"
                                        defaultValue={currentCart.shipping_address?.address_1}
                                        className="form-control" />
                                    <label className="form-label">{t('checkout.Address')} <span className="text-red" >*</span></label>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="float-input">
                                    <div className="select--arrow"></div>
                                    <select
                                        onChange={handleCountryChange}
                                        onBlur={handleChange}
                                        required
                                        name="country_code"
                                        defaultValue={currentCart.shipping_address?.country_code}
                                        placeholder={t('checkout.Country')}
                                        className="form-select"
                                    >
                                        {currentCart.region?.countries.slice().sort((a, b) => a.iso_2.localeCompare(b.iso_2)).map((country) => (
                                            <option value={country.iso_2} key={country.id}>{country.display_name}</option>
                                        ))}
                                    </select>
                                    <label className="form-label">{t('checkout.Country')} <span className="text-red" >*</span></label>
                                </div>
                            </div>
                            {states.length > 0 && 
                            <div className="col-lg-6 mb-3">
                                <div className="float-input">
                                    <div className="select--arrow"></div>
                                    <select
                                        onBlur={handleChange}
                                        required
                                        name="province"
                                        defaultValue={currentCart.shipping_address?.province}
                                        placeholder={t('checkout.Province')}
                                        className="form-select"
                                    >
                                        {states.map((state) => (
                                            <option value={state.state_code} key={state.state_code}>{state.name}</option>
                                        ))}
                                    </select>
                                    <label className="form-label">{t('checkout.Province')} <span className="text-red" >*</span></label>
                                </div>
                            </div>}
                            <div className="col-lg-6 mb-3">
                                <div className="float-input">
                                    <input
                                        onBlur={handleChange}
                                        required
                                        placeholder={t('checkout.Zip code')}
                                        type="text"
                                        name="postal_code"
                                        defaultValue={currentCart.shipping_address?.postal_code}
                                        className="form-control" />
                                    <label className="form-label">{t('checkout.Zip code')} <span className="text-red" >*</span></label>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="float-input">
                                    <input
                                        onBlur={handleChange}
                                        required
                                        placeholder={t('checkout.City')}
                                        type="text"
                                        name="city"
                                        defaultValue={currentCart.shipping_address?.city}
                                        className="form-control" />
                                    <label className="form-label">{t('checkout.City')} <span className="text-red" >*</span></label>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                            <PhoneInput
                                defaultCountry={currentCart.shipping_address?.country_code}
                                preferredCountries={currentCart.region?.countries.slice().sort((a, b) => a.iso_2.localeCompare(b.iso_2)).map(function(item) { return item.iso_2; }) }
                                onBlur={handleChange}
                                name="phone"
                                defaultValue={currentCart.shipping_address?.phone || ""}
                                onChange={(val) => {
                                    //setphone(val)
                                }}
                            />
                            
                        </div>
                        <p className="info_phone"><BsInfoCircle size={18} color="#282828" /> <span>{t('checkout.phoneInfo')}</span></p>
                        </div>
                        
                    </>}
            </div>
        </>
    );
}

export default Component;
export const getDeliveryPayload = (formRef, currentCart) => {
    const billing_address = {
        address_1: formRef.current?.address_1?.value,
        city: formRef.current?.city?.value,
        country_code: formRef.current?.country_code?.value || currentCart.shipping_address?.country_code,
        first_name: formRef.current?.first_name?.value,
        last_name: formRef.current?.last_name?.value,
        phone: formRef.current?.phone?.value,
        postal_code: formRef.current?.postal_code?.value,
        province: formRef.current?.province?.value,
    }
    return {
        billing_address,
        email: formRef.current?.email?.value,
        shipping_address: billing_address
    }
}
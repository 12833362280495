
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5"
import { Footer, Header, Loader, OrderItem } from "../../components";
import { useQuery } from "../../hooks/router";
import { useGetOrdersByEmailMutation } from "../../services/order";
import { useDispatch, useSelector } from "react-redux";
import { setOrders } from "../../slices/order";
import { BsEmojiNeutral } from "react-icons/bs";

const Page = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const query = useQuery();
    const dispatch = useDispatch();
    const { orders } = useSelector(state => state.order);
    const [getOrdersByEmail, { isLoading }] = useGetOrdersByEmailMutation();

    const getData = useCallback(async () => {
        if (query.get('email')) {
            const result = await getOrdersByEmail({
                email: query.get('email'),
                ...query.get('order_id') && { order_id: query.get('order_id') }
            }).unwrap()

            if (result.data) {
                dispatch(setOrders(result.data))
            }
        }
    },[getOrdersByEmail,query,dispatch])

    useEffect(() => {
        getData();
    }, [getData])

    return (
        <>
            <Header />
            <div className="orders" >
                <section className="section1" >
                    {isLoading ?
                        <Loader className={"flex-center"} />
                        :
                        <div className="container" >
                            <div className="d-flex align-items-center mb-4" >
                                <button
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                    className="btn btn-back" >
                                    <IoArrowBackOutline />
                                </button>
                                <h5 className="text-center mb-0 ms-3" >{t('orders.Order tracking')}</h5>
                            </div>
                            {orders.length > 0 ?
                                <div className="mb-5">
                                    {
                                        orders.map((item) => (
                                            <OrderItem item={item} key={item.id} />
                                        ))
                                    }
                                </div>
                                :
                                <div className="flex-center" >
                                    <BsEmojiNeutral size={100} color="#e5e5e5" />
                                    <h5 className="mt-4" >{t('No order found for this user')}</h5>
                                </div>
                            }
                        </div>
                    }
                </section>
            </div>
            <Footer />
        </>
    );
}

export default Page;
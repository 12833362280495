import { useTranslation } from "react-i18next"
import { FiDownload } from "react-icons/fi"
import { FaGoogleDrive, FaDropbox } from "react-icons/fa"
import moment from 'moment';
import { getAmount } from "../../helpers/price";

const Component = ({ item }) => {
    const { t } = useTranslation();

    return (
        <div className="order-item">
            <h4 className="text-green mb-3" ><b>#{item.display_id}</b></h4>
            <div className="row" >
                <div className="col-md-8" >
                    <h5 className="mb-4" >{t('orders.Order information')}</h5>
                    <p>{t('orders.Date')}: <b>{moment(item.created_at).format('DD/MM/YYYY')}</b></p>
                    <p>{t('orders.Order status')}: <b>{item.status}</b></p>
                    <p>{t('orders.Payment method')}: <b>{item.payments[0]?.provider_id}</b></p>
                    <p>{t('orders.Payment status')}: <b>{item.payment_status}</b></p>
                </div>
                <div className="col-md-4 text-end" >
                    <h5 className="mb-4" >{t('orders.Customer information')}</h5>
                    <p>{t('orders.Full name')}: <b>{item.shipping_address.first_name} {item.shipping_address.last_name}</b></p>
                    <p>{t('orders.Email')}: <b>{item.email}</b></p>
                </div>
            </div>
            <div className="row" >
                <div className="col-md-12" >
                    <h5 className="mt-5 mb-4" >{t('orders.Order detail')}</h5>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">{t('orders.Image')}</th>
                                <th scope="col">{t('orders.Product')}</th>
                                <th className="text-end" scope="col">{t('orders.Amount')}</th>
                                <th className="text-end" scope="col">{t('orders.Quantity')}</th>
                                <th className="text-end" scope="col">{t('orders.Total')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {item.items.map((prod) => (
                                <tr key={prod.id} >
                                    <td>
                                        <div style={{ backgroundImage: `url(${prod.thumbnail})` }} className="img" ></div>
                                    </td>
                                    <td>
                                        <p>{prod.title}</p>
                                        <div className="btns" >
                                            {prod.variant.download_url && <a target={"_blank"} href={prod.variant.download_url} className="btn btn-dark" ><FiDownload color="#007bff" /> {t('orders.Download File')}</a>}
                                            {prod.variant.drive_url && <a target={"_blank"} href={prod.variant.drive_url} className="btn btn-dark" ><FaGoogleDrive color="#007bff" /> {t('orders.Download From G drive')}</a>}
                                            {prod.variant.dropox_url && <a target={"_blank"} href={prod.variant.dropox_url} className="btn btn-dark" ><FaDropbox color="#007bff" /> {t('orders.Download From Dropbox')}</a>}
                                        </div>
                                    </td>
                                    <td align="right">{`${getAmount(prod.unit_price, item.region)}`}</td>
                                    <td align="right">
                                        {prod.quantity}
                                    </td>
                                    <td align="right">{`${getAmount(prod.unit_price * prod.quantity, item.region)}`}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="d-flex flex-column align-items-end" >
                {item.tip_total > 0 && <p>{t('orders.Tip')}: <b>{`${getAmount(item.tip_total, item.region)}`}</b></p>}
                <p>{t('orders.Total')}: <b>{`${getAmount(item.total, item.region)}`}</b></p>
            </div>
        </div>
    )
}

export default Component

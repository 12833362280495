
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FiInfo } from "react-icons/fi"
import { Footer, Header } from "../../components";

const Page = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const params = Object.fromEntries(formData.entries())
        history.push(`/orders?email=${params.email}${params.order_id ? `&order_id=${params.order_id}` : ''}`)
    }
    return (
        <>
            <Header />
            <div className="tracking" >
                <section className="section1" >
                    <div className="container" >
                        <div className="row" >
                            <div className="col-md-6 mx-auto" >
                                <h5 className="text-center" >{t('tracking.Order tracking')}</h5>
                                <p className="text-center mb-5" >{t('tracking.Tracking your order status')}</p>
                                <form className="order-form" onSubmit={handleSubmit} >
                                    <div className="mb-4 alert">
                                        <FiInfo color={"#0c5460"} className="me-2" size={18} />
                                        <span>{t('tracking.Please, use the email address used to order.')}</span>
                                    </div>
                                    <div className="mb-4">
                                        <label className="form-label">{t('tracking.Email address')} <span className="text-red" >*</span></label>
                                        <input
                                            required
                                            name="email"
                                            placeholder={t('tracking.Please enter your email address')}
                                            type="email"
                                            className="form-control" />
                                    </div>
                                    {/* <div className="mb-4">
                                        <label className="form-label">{t('tracking.Order ID (Optional)')}</label>
                                        <input
                                            placeholder={t('tracking.Order ID')}
                                            type="text"
                                            name="order_id"
                                            className="form-control" />
                                    </div> */}
                                    <button className="btn btn-primary" type="submit">{t('tracking.FIND')}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}

export default Page;
import * as React from "react"

const Component = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={102}
    height={52}
    viewBox="-252.3 356.1 163 80.9"
    {...props}
  >
    <path
      stroke="#B2B2B2"
      strokeWidth={2}
      strokeMiterlimit={10}
      d="M-108.9 404.1v30c0 1.1-.9 2-2 2H-231c-1.1 0-2-.9-2-2v-75c0-1.1.9-2 2-2h120.1c1.1 0 2 .9 2 2v37m-124.1-29h124.1"
      fill="none"
    />
    <circle fill="#B2B2B2" cx={-227.8} cy={361.9} r={1.8} />
    <circle fill="#B2B2B2" cx={-222.2} cy={361.9} r={1.8} />
    <circle fill="#B2B2B2" cx={-216.6} cy={361.9} r={1.8} />
    <path
      stroke="#B2B2B2"
      strokeWidth={2}
      strokeMiterlimit={10}
      d="M-128.7 400.1H-92m-3.6-4.1 4 4.1-4 4.1"
      fill="none"
    />
  </svg>
)

export default Component

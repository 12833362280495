
import React, { useCallback, useState } from "react";
import { Loader } from "../../components";
import { useEffect } from "react";
import { useQuery } from "../../hooks/router";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useAddProductToCartMutation, useCreateCartMutation, usePostCartMutation } from "../../services/cart";
import { BsEmojiNeutral } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { setToast } from "../../slices/global";
import { useDispatch } from "react-redux";
import { getMetadata } from "../../helpers/cart";
import { getAmount, getRawAmount } from "../../helpers/price";
import ReactGA from "react-ga4";
import TiktokPixel from 'tiktok-pixel';

const Page = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const quantity = useQuery().get('quantity') || 1;
    const { search } = useLocation();
    const { t } = useTranslation();
    const { id: variant_id } = useParams();
    const [createCart, { isLoading }] = useCreateCartMutation();
    const [addProductToCart, { isLoading: isLoading2 }] = useAddProductToCartMutation();
    const [postCart, { isLoading: isLoading3 }] = usePostCartMutation();
    const [ currentCart, setcurrentCart ] = useState([]);

    const addData = useCallback(async () => {
        try {
            const variantsArray = variant_id.split(',').map((i) => ({quantity: parseInt(i.split(':')[1] || 1), variant_id: i.split(':')[0]}));
            const metadata = {...getMetadata(search),variant_id}
            const res = await createCart({
                items: variantsArray,
                metadata: {...variantsArray, ...metadata},
            }).unwrap();
            if (res.cart) {
                setcurrentCart(res.cart);
            }
        } catch (err) {
            dispatch(setToast({
                visible: true,
                type: "danger",
                title: "Error",
                text: err.data?.message
            }))
        }
    }, [variant_id, createCart, addProductToCart, history, quantity])

    useEffect(() => {
        addData();
    }, [addData]);

   
    useEffect(() => {

        if (currentCart.region && currentCart.sales_channel?.googleanalytics) {
            ReactGA.initialize(currentCart.sales_channel.googleanalytics);
            ReactGA.send({ hitType: "pageview", title: "Cart page" })
            ReactGA.event("add_to_cart", {
                transaction_id: currentCart.id,
                currency: currentCart.region.currency_code,
                value: getRawAmount(currentCart.total, currentCart.region),
                items: [{
                    item_id: currentCart.items[0].variant?.id,
                    item_name: currentCart.items[0].title,
                    quantity: currentCart.items[0].quantity,
                    price: getRawAmount(currentCart.items[0].total, currentCart.region),
                    currency: currentCart.region.currency_code
                }]
            });
            
        }
        if (currentCart.region && currentCart.sales_channel?.tiktokpixel) {
            TiktokPixel.init(currentCart.sales_channel.tiktokpixel);
            TiktokPixel.track("AddToCart", {
                content_type: 'product',
                quantity: currentCart.items[0].quantity,
                content_name: currentCart.items[0].title,
                content_id: currentCart.items[0].variant?.id,
                currency: currentCart.region.currency_code,
                value: getRawAmount(currentCart.items[0].total, currentCart.region),
            });
        }
        if (currentCart.id){
            history.replace(`/checkout/${currentCart.id.split('cart_')[1]}`);
        }

    }, [currentCart.id])

    return (
        <div className="checkout" >
            <section className="section1" >
                {(isLoading || isLoading2 || isLoading3) ?
                    <Loader className={"flex-center"} />
                    :
                    <div className="flex-center" >
                        <BsEmojiNeutral size={100} color="#e5e5e5" />
                        <h5 className="mt-4" >{t('checkout.Product Not Valid')}</h5>
                    </div>
                }
            </section >
        </div >
    );
}

export default Page;
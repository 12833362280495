import { RiLoader2Fill } from "react-icons/ri"

const Component = ({ className, size = 40 }) => {

    return (
        <div className={`loader-con ${className}`}>
            <RiLoader2Fill className="loader" size={size} />
        </div>
    )
}

export default Component


import { useTranslation } from 'react-i18next'
import { RiFacebookFill, RiInstagramLine, RiYoutubeFill } from 'react-icons/ri'

const Component = () => {
  const { t } = useTranslation();

  const handleSendEmail = () => {

  }

  return (
    <footer >
      <div className='container' >
        <ul className="d-flex flex-row justify-content-center navbar-nav ">
          <li className="nav-item mx-2">
            <a className="nav-link active" href="/tracking">{t('footer.Track Order')}</a>
          </li>
          <li className="nav-item mx-2">
            <a className="nav-link" href="/">{t('footer.Contact')}</a>
          </li>
        </ul>
        <div className='d-flex justify-content-center my-5' >
          <a target="_blank" rel="noreferrer" className='btn btn-social' href={process.env.REACT_APP_FACEBOOK_LINK} ><RiFacebookFill color="#06294c" size={16} /></a>
          <a target="_blank" rel="noreferrer" className='btn btn-social' href={process.env.REACT_APP_INSTAGRAM_LINK} ><RiInstagramLine color="#06294c" size={16} /></a>
          <a target="_blank" rel="noreferrer" className='btn btn-social' href={process.env.REACT_APP_YOUTUBE_LINK} ><RiYoutubeFill color="#06294c" size={16} /></a>
        </div>
        <div className='d-flex flex-column align-items-center mb-4' >
          <p className='text-center mb-4' >{t('footer.Subscribe & Receive 10% off your first order')}</p>
          <form onSubmit={handleSendEmail} className="newsletter" >
            <input
              required
              placeholder={t('footer.Please enter your email')}
              type="email"
              className="form-control" />
            <button className="btn btn-primary" type="submit">{t('footer.Send')}</button>
          </form>
        </div>
      </div>
      <div className='policy text-center' >
        {`${t('footer.Copyright All rights reserved', { name: process.env.REACT_APP_STORE_NAME, year: new Date().getFullYear() })}`}
      </div>
    </footer>
  )
}

export default Component


import React from "react";
import { Footer, Header } from "../../components";

const Page = () => {
    return (
        <>
            <Header />
            <div className="home" >
                <div className="container" >
                    <h1>Contact</h1>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Page;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_ENDPOINT,
})

//const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 })

const api = createApi({
  baseQuery,
  endpoints: () => ({}),
})

export default api;






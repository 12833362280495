import { createSlice } from '@reduxjs/toolkit'

const initialeState = {
    currentProduct: {}
};

const slice = createSlice({
    name: 'product',
    initialState: initialeState,
    reducers: {
        setCurrentProduct: (
            state,
            { payload }
        ) => {
            state.currentProduct = payload
        },
    },
})

export const { setCurrentProduct } = slice.actions

export default slice.reducer
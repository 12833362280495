import { useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setToast } from '../../slices/global';
import { Loader } from "../../components";
import { BsArrowRight } from "react-icons/bs";

const Component = ({parent_cart_id}) => {
    const history = useHistory();
    const stripe = useStripe();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setloading] = useState(false);
    const { currentCart } = useSelector(state => state.cart);

    const handlePay = async () => {
        if (!stripe || !currentCart.payment_method_id) {
            return;
        }

        setloading(true);
        const { error } = await stripe.confirmCardPayment(
            currentCart.payment_session.data.client_secret,
            {
                payment_method: currentCart.payment_method_id
            }
        );

        setloading(false);
        if (error) {
            dispatch(setToast({
                visible: true,
                type: "danger",
                title: "Error",
                text: error.data?.message
            }))
        } else {
            history.replace(`/complete?id=${currentCart.id.split('cart_')[1]}&parent_cart_id=${parent_cart_id}&sold_variant=${currentCart.items[0]?.variant_id}`)
        }
    }

    return (
        <button
            onClick={handlePay}
            className={`btn btn-primary ${loading ? 'loading': ''}`} >
                {loading ?
                    <Loader  size={20}/>
                :
                     <>{t('checkout.Complete order')} <BsArrowRight color="white" size={18} /></>
                } 

            </button>
    )
}

export default Component

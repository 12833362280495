
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAmount } from "../../helpers/price";
import { useEditShippingMethodMutation } from "../../services/cart";
import { setCurrentCart } from "../../slices/cart";
import { setToast } from "../../slices/global";
const Component = () => {
    const elsRef = useRef([]);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { shippingOptions, currentCart } = useSelector(state => state.cart)
    const [editShippingMethod] = useEditShippingMethodMutation();

    useEffect(() => {
        if (shippingOptions.length > 0) {
            currentCart.items[0].variant.is_only_digital ? handleChange(shippingOptions[0]) : elsRef.current[0]?.click();
        }
        
    }, [shippingOptions.length])

    const handleChange = async (el, i) => {
        try {
            const res = await editShippingMethod({ id: currentCart.id, params: { option_id: el.id } }).unwrap()
            if (res.cart) {
                dispatch(setCurrentCart(res.cart));
            }
        } catch (err) {
            dispatch(setToast({
                visible: true,
                type: "danger",
                title: "Error",
                text: err.data?.message
            }))
        }
    }

    if (currentCart.items[0].variant.is_only_digital) {
        return null;
    }

    return (
        <>
            <h5 className="mb-4" >{t('checkout.Shipping method')}</h5>
            <div className="shippings mb-5" >
                {shippingOptions.map((el, i) => (
                        <div className="pointer-cursor" key={el.id} onClick={() => elsRef.current[i].click()} >
                            <input
                                onChange={() => handleChange(el, i)}
                                ref={ref => elsRef.current.push(ref)} className="form-check-input"
                                defaultChecked={el?.amount === currentCart?.shipping_total} 
                                type={"radio"}
                                name="shipping_method"
                                value={"free_shipping"} />
                            <label className="form-check-label">
                                {el.name} - <b>{el.amount > 0 ? getAmount(el.amount, currentCart.region) : t('Free')}</b>
                            </label>
                        </div>
                    )
                )}
            </div>
        </>
    );
}

export default Component;
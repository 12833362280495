
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Coupon, CountDown, PaypalForm, ProductItem, Tips, ShippingOptions, Loader, DeliveryInfo, PaymentMethods, SvgSecureLogos } from "../../components";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useEffect } from "react";
import { useAddPaymentSessionsMutation, useGetCartMutation, useGetShippingsMutation, useSetPaymentSessionMutation } from "../../services/cart";
import { setCurrentCart, setShippingOptions } from "../../slices/cart";
import { useDispatch, useSelector } from "react-redux";
import { BsEmojiNeutral, BsShieldCheck, BsArrowRight } from "react-icons/bs";
import { getAmount, getRawAmount } from "../../helpers/price";
import ReactGA from "react-ga4";
import TiktokPixel from 'tiktok-pixel';
import { setHeadInfo } from "../../helpers/meta";
import { setToast } from "../../slices/global";


const Page = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const formRef = useRef();
    const history = useHistory();
    const { t } = useTranslation();
    const { currentCart } = useSelector(state => state.cart)
    const [confirming, setconfirming] = useState(false)
    const [paying, setpaying] = useState(false)
    const [getCart, { isLoading }] = useGetCartMutation();
    const [getShippings, { isLoading: isLoading2 }] = useGetShippingsMutation();
    const [setPayment] = useSetPaymentSessionMutation();
    const [addPayments] = useAddPaymentSessionsMutation();
    const [paymentSession, setpaymentSession] = useState({ id: 'stripe' })

    const fetchCart = useCallback(async () => {
        const res = await getCart(`cart_${id}`).unwrap()
        if (res.cart) {
            if (res.cart.payment_id) {
                history.replace(`/success/${id}`)
            } else {
                fetchShippings(res.cart);
                dispatch(setCurrentCart(res.cart));
            }
        }
    }, [id, getCart, dispatch, history])

    const fetchShippings = useCallback(async (data) => {
        const res = await getShippings(`${data.id}?admin_only=${data.items[0].variant.is_only_digital ? 'true' : 'false'}`).unwrap()
        if (res.shipping_options) {
            dispatch(setShippingOptions(res.shipping_options));
        }
    }, [id, getShippings, dispatch])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (paymentSession?.id === "stripe") {
            setconfirming(true)
        } else {
            payMaual();
        }
    }

    const payMaual = async () => {
        try {
            setpaying(true)
            await addPayments(currentCart.id).unwrap()
            const res = await setPayment({ id: currentCart.id, params: { provider_id: 'manual' } }).unwrap()
            if (res.cart) {
                return history.replace(`/complete?id=${currentCart.id.split('cart_')[1]}`)
            }
        } catch (error) {
            console.log("error", error)
            dispatch(setToast({
                visible: true,
                type: "danger",
                title: "Error",
                text: error.data?.message
            }))
        }
        setpaying(false)
    }

    useEffect(() => {
        fetchCart();
    }, [fetchCart]);

    useEffect(() => {
        if (currentCart.region && currentCart.sales_channel.googleanalytics) {
            ReactGA.initialize(currentCart.sales_channel.googleanalytics);
            ReactGA.send({ hitType: "pageview", title: "Checkout page" });
            ReactGA.event("begin_checkout", {
                transaction_id: currentCart.id,
                currency: currentCart.region.currency_code,
                value: getRawAmount(currentCart.total, currentCart.region),
                items: [{
                    item_id: currentCart.items[0].variant?.id,
                    item_name: currentCart.items[0].title,
                    quantity: currentCart.items[0].quantity,
                    price: getRawAmount(currentCart.items[0].total, currentCart.region),
                    currency: currentCart.region.currency_code
                }]
            });
        }
        if (currentCart.region && currentCart.sales_channel.tiktokpixel) {
            TiktokPixel.init(currentCart.sales_channel.tiktokpixel);
            TiktokPixel.track("InitiateCheckout", {
                content_type: 'product',
                quantity: currentCart.items[0].quantity,
                content_name: currentCart.items[0].title,
                content_id: currentCart.items[0].variant?.id,
                currency: currentCart.region.currency_code,
                value: getRawAmount(currentCart.items[0].total, currentCart.region),
            });
        }
    }, [currentCart.id]);

    useEffect(() => {
        if (currentCart.sales_channel?.favicon_url) {
            setHeadInfo(currentCart.sales_channel.favicon_url, currentCart.sales_channel.name);
        }
    }, [currentCart.sales_channel])

    return (
        <div className="checkout" >
            <section className="section1" >
                {isLoading || !currentCart?.id ?
                    <Loader className={"flex-center"} />
                    :
                    currentCart.id ?
                        <div className="container" >
                            <form onSubmit={handleSubmit} ref={formRef}  >
                                <div className="row" >
                                    <div className="col-lg-7" >
                                        {currentCart?.sales_channel?.logo_url &&
                                            <>  
                                                <div className="logo_container">
                                                    <img alt={currentCart?.sales_channel?.name} src={currentCart?.sales_channel?.logo_url} className="logo" />
                                                    <div className="logo_checkout">
                                                        <BsShieldCheck size={22} color="#333" />
                                                        <span>Secured Checkout</span>
                                                    </div>
                                                </div>
                                                <div className="divider mt-3 mb-3" ></div>
                                            </>
                                        }
                                        <SvgSecureLogos />
                                        {process.env.REACT_APP_COUNTDOWN_ENABLED && <CountDown targetDate={Date.now() + 900000} id={id} className='my-4' />}
                                    </div>
                                    <div className="col-lg-4 order-lg-2 order-2 ms-md-auto product-view-con" >
                                        <div className="product-view" >
                                            <h6 className="showcart">{t('Shopping Cart')}</h6>
                                            {currentCart.items.map((prod) => (
                                                <ProductItem key={prod.id} item={prod} region={currentCart.region} />
                                            ))}
                                            <div className="divider mb-3" ></div>
                                            <Coupon
                                                cart={currentCart}
                                                className={"mb-3"} />
                                            <div className="divider mb-3" ></div>
                                            {currentCart.subtotal > 0 &&
                                                <div className="d-flex align-item-start justify-content-between mb-2" >
                                                    <span>{t('checkout.Sub total')}:</span>
                                                    <span>{getAmount(currentCart.subtotal, currentCart.region)}</span>
                                                </div>
                                            }
                                            {currentCart.shipping_total > 0 &&
                                                <div className="d-flex align-item-start justify-content-between mb-2" >
                                                    <span>{t('checkout.Shipping Fees')}:</span>
                                                    <span>{getAmount(currentCart.shipping_total, currentCart.region)}</span>
                                                </div>
                                            }
                                            {currentCart.tax_total > 0 &&
                                                <div className="d-flex align-item-start justify-content-between mb-2" >
                                                    <span>{t('checkout.Fees')}:</span>
                                                    <span>{getAmount(currentCart.tax_total, currentCart.region)}</span>
                                                </div>
                                            }
                                            {currentCart.tip_total > 0 &&
                                                <div className="d-flex align-item-start justify-content-between mb-2" >
                                                    <span>{t('checkout.Tip')}:</span>
                                                    <span>{getAmount(currentCart.tip_total, currentCart.region)}</span>
                                                </div>
                                            }
                                            {currentCart.discount_total > 0 &&
                                                <div className="d-flex align-item-start justify-content-between mb-2" >
                                                    <span>{t('checkout.Discount')}:</span>
                                                    <span>-{getAmount(currentCart.discount_total, currentCart.region)}</span>
                                                </div>
                                            }
                                            {currentCart.total > 0 &&
                                                <div className="d-flex align-item-start justify-content-between mb-2" >
                                                    <span>{t('checkout.Total')}:</span>
                                                    <span><span className="currency_title">{currentCart.region.currency_code}</span> <b>{getAmount(currentCart.total, currentCart.region)}</b></span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-7 order-lg-1 order-2" >
                                        
                                        <DeliveryInfo formRef={formRef} />
                                        <ShippingOptions />
                                        {currentCart?.region?.payment_providers.length > 0 &&
                                            <PaymentMethods
                                                paying={paying}
                                                setpaying={setpaying}
                                                confirming={confirming}
                                                setconfirming={setconfirming}
                                                availableProviders={currentCart.region.payment_providers}
                                                paymentSession={paymentSession}
                                                setpaymentSession={setpaymentSession}
                                            />
                                        }
                                        {process.env.REACT_APP_TIPS_ENABLED && currentCart.id &&
                                            <>
                                                <h5 className="mb-4" >{t('checkout.Add tip')}</h5>
                                                <Tips className="mb-4" />
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-lg-7" >
                                        <PayPalScriptProvider
                                            options={{
                                                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                                currency: currentCart?.region.currency_code.toUpperCase(),
                                                intent: "authorize",
                                                "disable-funding": "card",
                                            }}>
                                            {paymentSession?.id === "paypal" && <PaypalForm />}
                                        </PayPalScriptProvider>
                                        {["stripe", "manual"].includes(paymentSession?.id) &&
                                            <button
                                                type="submit"
                                                className={`btn btn-primary w-100 ${isLoading || isLoading2 || paying ? 'loading' : ''}`} >
                                                {isLoading || isLoading2 || paying ?
                                                    <Loader  size={20}/>
                                                :
                                                    <>{t('checkout.Complete order')} <BsArrowRight color="white" size={18} /></>
                                                }       
                                            </button>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                            <p className="text-center text-sm pt-5 pb-3">{t('Copyright')} © 2023. {currentCart.sales_channel.name}. {t('All rights reserved')}</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        :
                        <div className="flex-center" >
                            <BsEmojiNeutral size={100} color="#e5e5e5" />
                            <h5 className="mt-4" >{t('checkout.Empty cart')}</h5>
                            <a href="/" className="bnt btn-primary">{t('checkout.Explore products')}</a>
                        </div>
                }
            </section >
        </div >
    );
}

export default Page;
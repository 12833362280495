import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { Checkout, Complete, Home, Orders, Success, Tracking, Contact, Cart, Upsell } from "../pages";

const AppNavigator = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/"><Home /></Route>
                <Route path="/contact"><Contact /></Route>
                <Route path="/tracking"><Tracking /></Route>
                <Route path="/checkout/:id" exact><Checkout /></Route>
                <Route path="/success/:id"><Success /></Route>
                <Route path="/complete"><Complete /></Route>
                <Route path="/orders"><Orders /></Route>
                <Route path="/cart/:id"><Cart /></Route>
                <Route path="/upsell"><Upsell /></Route>
            </Switch>
        </Router>
    );
}

export default AppNavigator;

import { useState } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import '../../../node_modules/react-intl-tel-input/dist/main.css';

const Component = ({ onChange,onBlur, ...rest }) => {
    const [error, seterror] = useState(false)

    const onPhoneNumberChange = (isValid, value, selectedCountryData, fullNumber, extension) => {
        onChange({ isValid, fullNumber: fullNumber?.replace(/\s/g, '') });
    }

    return (
        <>
        <IntlTelInput
            {...rest}
            fieldName={rest.name}
            containerClassName="intl-tel-input w-100"
            inputClassName={`form-control ${error ? 'is-invalid' : ''}`}
            defaultCountry={rest.defaultCountry}
            preferredCountries={rest.preferredCountries}
            //onlyCountries={['fr']}
            autoPlaceholder={true}
            nationalMode={false}
            onPhoneNumberChange={onPhoneNumberChange}
            onPhoneNumberBlur={onBlur}
        />
        </>
    )
}

export default Component

import { createSlice } from '@reduxjs/toolkit'

const initialeState = { user: {}, accessToken: null };

const slice = createSlice({
    name: 'user',
    initialState: initialeState,
    reducers: {
        authenticateUser: (
            state,
            { payload: { user, accessToken } }
        ) => {
            state.user = user
            state.accessToken = accessToken
        },
        logoutUser: (state) => {
            state.user = {};
            state.accessToken = null;
        }
    },
})

export const { setUser, authenticateUser, logoutUser, setBank } = slice.actions

export default slice.reducer
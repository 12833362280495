import { combineReducers } from '@reduxjs/toolkit'
import api from '../services/apiClient'
import user from './user'
import global from './global'
import order from './order'
import cart from './cart'
import product from './product'

export default combineReducers({
    [api.reducerPath]: api.reducer,
    user,
    global,
    order,
    cart,
    product
})
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAddPaymentSessionsMutation, useSetPaymentSessionMutation } from '../../services/cart';
import { setCurrentCart } from '../../slices/cart';
import { setToast } from '../../slices/global';

const Component = ({ confirming, setconfirming, setpaying }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const btnRef = useRef(null);
    const { t } = useTranslation();
    const { currentCart } = useSelector(state => state.cart);
    const [setPayment, { isLoading }] = useSetPaymentSessionMutation();
    const [addPayments, { isLoading: isLoading2 }] = useAddPaymentSessionsMutation();
    const [paymentMethod, setpaymentMethod] = useState(null)

    useEffect(() => {
        if (confirming) {
            handlePay()
            setconfirming(false)
        }
    }, [confirming])

    useEffect(() => {
        if (currentCart.payment_session?.data?.client_secret && paymentMethod?.id) {
            confirmPayment();
        }else if (paymentMethod?.id && !currentCart.payment_session?.data?.client_secret){
            selectPayment();
        }
    }, [currentCart.payment_session?.data, paymentMethod])

    const selectPayment = async () => {
        try {
            await addPayments(currentCart.id).unwrap()
            const res = await setPayment({ id: currentCart.id, params: { provider_id: 'stripe' } }).unwrap()
            if (res.cart) {
                dispatch(setCurrentCart(res.cart));
            }
        } catch (error) {
            setpaying(false);
            dispatch(setToast({
                visible: true,
                type: "danger",
                title: "Error",
                text: error.data?.message
            }))
        }
    }

    const handlePay = async () => {
        if (!stripe || !elements) {
            return;
        }

        setpaying(true);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement)
        });

        if (error) {
            setpaying(false);
            dispatch(setToast({
                visible: true,
                type: "danger",
                title: "Error",
                text: error?.message
            }))
        } else {
            setpaymentMethod(paymentMethod)
        }
    }

    const confirmPayment = async () => {
        const { error } = await stripe.confirmCardPayment(
            currentCart.payment_session.data.client_secret,
            {
                payment_method: paymentMethod.id
            }
        );

        if (error) {
            setpaying(false);
            return dispatch(setToast({
                visible: true,
                type: "danger",
                title: "Error",
                text: error.message
            }))
        }

        setpaying(false);
        history.replace(`/complete?id=${currentCart.id.split('cart_')[1]}`)
    }

    return (
        <>
            <p>{t('Pay securely with your credit card. Credit card informations are not stored in our servers.')}</p>
            <div className="mb-3 " >
                <CardNumberElement
                    options={{ showIcon: true }}
                    className="form-control"
                    placeholder="1234 1234 1234 1234"
                />
            </div>
            <div className="row">
                <div className="col-8">
                    <CardExpiryElement
                        className="form-control"
                    />
                </div>
                <div className="col-4">
                    <CardCvcElement
                        className="form-control"
                    />
                </div>
            </div>
            {/* <PaymentElement /> */}
            <button type="submit" ref={btnRef} hidden />
        </>
    )
}

export default Component

import { createSlice } from '@reduxjs/toolkit'

const InitialState = {
  toast: {
    visible: false,
    type: "danger",
    title: "Error",
    text: "Something went wrong !",
  }
}

const slice = createSlice({
  name: 'global',
  initialState: InitialState,
  reducers: {
    setToast: (state, { payload: toast }) => {
      state.toast = { ...state.toast, ...toast }
    },
  },
})

export const { setToast } = slice.actions

export default slice.reducer

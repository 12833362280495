import { configureStore } from '@reduxjs/toolkit'
import api from '../services/apiClient'
import slices from "../slices";


export const store = configureStore({
    reducer: slices,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                //ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                ignoredPaths: ["uploads"]
            },
        }).concat(api.middleware),
})

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../../slices/global";

const Component = () => {
    const dispatch = useDispatch()
    const { toast } = useSelector((state) => state.global)

    useEffect(() => {
        let timeout = null;
        if (toast.visible) {
            timeout = setTimeout(() => {
                dispatch(setToast({
                    visible: false
                }))
            }, 4000);
        } else if (timeout) {
            clearTimeout(timeout);
        }
    }, [dispatch, toast.visible])

    return (
        <div className={`custom-toast-con ${toast.visible ? 'visible' : ''}`} >
            <div className={`custom-toast ${toast.type}`} >
                {toast.title && <p className="title">{toast.title}</p>}
                <span className="subtitle">{toast.text}</span>
            </div>
        </div>
    );
}

export default Component;
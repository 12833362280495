import { createSlice } from '@reduxjs/toolkit'

const initialeState = {
    orders: [],
    createdOrder: {}
};

const slice = createSlice({
    name: 'order',
    initialState: initialeState,
    reducers: {
        setOrders: (
            state,
            { payload }
        ) => {
            state.orders = payload
        },
        setCreatedOrder: (
            state,
            { payload }
        ) => {
            state.createdOrder = payload
        },
    },
})

export const { setOrders, setCreatedOrder } = slice.actions

export default slice.reducer
import apiClient from './apiClient'

export const api = apiClient.injectEndpoints({
    endpoints: (builder) => ({
        getOrdersByEmail: builder.mutation({
            query: (params) => ({
                url: '/store/order-by-email',
                method: 'GET',
                params,
            }),
        }),
        getOrderById: builder.mutation({
            query: (id) => ({
                url: `/store/orders/${id}`,
                method: 'GET',
            }),
        }),
        completeOrder: builder.mutation({
            query: (id) => ({
                url: `/store/orders/success/${id}`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetOrdersByEmailMutation,
    useGetOrderByIdMutation,
    useCompleteOrderMutation
} = api

import { formatAmount, computeAmount } from "medusa-react"

export const getAmount = (amount,region) => {
    if (!amount) {
        return
    }

    return formatAmount({ amount, region, includeTaxes: false, locale: navigator.languages[0] ? navigator.languages[0] : 'en-US' })
}

export const getRawAmount = (amount,region) => {

	if (!amount) {
        amount = 0;
    }

    return computeAmount({ amount, region, includeTaxes: false })
}
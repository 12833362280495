import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom";

const Component = () => {
    const { t } = useTranslation();
    const location = useLocation();
    return (
        <nav className="navbar navbar-expand-lg bg-light">
            <div className="container">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <a className="navbar-brand" href={"/"}><img src={process.env.REACT_APP_LOGO_URL} alt={process.env.REACT_APP_STORE_NAME} /></a>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className={`nav-link ${location.pathname === "/" ? 'active' : ''}`} href="/tracking">{t('header.Track Order')}</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${location.pathname === "/checkout" ? 'active' : ''}`} href="/checkout">{t('Checkout')}</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${location.pathname === "/contact" ? 'active' : ''}`} href="/contact">{t('header.Contact')}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Component

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { getTimeLeft } from "../../helpers/timer";
import Cookies from 'js-cookie'

const Component = ({ targetDate, className,id }) => {
    const { t } = useTranslation();
    const [timer, settimer] = useState(Cookies.get(`countdown_diff_${id}`) ?
        parseInt(Cookies.get(`countdown_diff_${id}`))
        :
        targetDate - new Date().getTime());
    const [timeLeft, setTimeLeft] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const cookie = parseInt(Cookies.get(`countdown_diff_${id}`));
            if (cookie > 1000) {
                const diff = cookie ? cookie - 1000 : timer - 1000;
                settimer(diff);
            } else {
                Cookies.remove(`countdown_diff_${id}`);
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [targetDate])

    useEffect(() => {
        setTimeLeft(getTimeLeft(timer))
        Cookies.set(`countdown_diff_${id}`, timer - 1, { expires: 0.04 })
    }, [timer])

    return (
        timer > 1000 &&
        <div className={`count-down-con ${className}`}>
            {t(timer > 1000 ? 'Your order is reserved for' : 'Order reservation ended.')}
            {timer > 1000 && <b>{` ${timeLeft[1]}:${timeLeft[2]}:${timeLeft[3]}`}</b>}
        </div>
    )
}

export default Component
